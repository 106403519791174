import React from 'react';

const Logo = ({ className = '', isFull = false }) => {
  if (isFull) {
    return (
      <div className={className}>
        <svg width="auto" height="auto" viewBox="0 0 233 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M79.8026 25.7371H74.5705V10.2617H69.8682V25.7371H64.6361V10.2617H60V29.6715H84.4386V10.2617H79.8026V25.7371Z" fill="currentColor"/>
          <path d="M123.512 11.2459C122.121 10.3934 120.333 10 118.28 10C116.16 10 114.372 10.3934 112.981 11.2459C111.59 12.0328 110.531 13.2131 109.868 14.7213C109.206 16.1639 108.875 17.9344 108.875 19.9016C108.875 21.8688 109.206 23.6393 109.868 25.1475C110.531 26.6557 111.59 27.836 112.981 28.6885C114.372 29.541 116.16 29.9344 118.213 29.9344C120.333 29.9344 122.121 29.541 123.445 28.6885C124.836 27.836 125.83 26.6557 126.492 25.2131C127.154 23.7049 127.485 22 127.485 19.9672C127.485 18 127.154 16.2295 126.492 14.7869C125.896 13.2787 124.902 12.0984 123.512 11.2459ZM122.717 19.9672C122.717 21.8688 122.386 23.377 121.657 24.4262C120.995 25.4754 119.869 25.9344 118.28 25.9344C116.69 25.9344 115.63 25.4098 114.902 24.4262C114.173 23.377 113.842 21.8688 113.842 19.9672C113.842 18.0656 114.173 16.5574 114.902 15.5082C115.564 14.459 116.69 13.9344 118.28 13.9344C119.869 13.9344 120.995 14.459 121.657 15.4426C122.32 16.5574 122.717 18.0656 122.717 19.9672Z" fill="currentColor"/>
          <path d="M132.917 24.4256C132.784 25.1469 132.122 25.6715 131.394 25.6715H129.605V29.606H132.983C134.97 29.606 136.692 28.2289 137.023 26.2617L139.275 14.1961H143.315V29.6715H147.951V10.2617H135.566L132.917 24.4256Z" fill="currentColor"/>
          <path d="M187.025 11.2459C185.635 10.3934 183.913 10 181.793 10C179.674 10 177.886 10.3934 176.495 11.2459C175.104 12.0328 174.044 13.2131 173.382 14.7213C172.72 16.1639 172.389 17.9344 172.389 19.9016C172.389 21.8688 172.72 23.6393 173.382 25.1475C174.044 26.6557 175.104 27.836 176.495 28.6885C177.886 29.541 179.674 29.9344 181.727 29.9344C183.846 29.9344 185.635 29.541 186.959 28.6885C188.35 27.836 189.343 26.6557 190.006 25.2131C190.668 23.7049 190.999 22 190.999 19.9672C190.999 18 190.668 16.2295 190.006 14.7869C189.476 13.2787 188.416 12.0984 187.025 11.2459ZM186.231 19.9672C186.231 21.8688 185.899 23.377 185.171 24.4262C184.509 25.4754 183.383 25.9344 181.793 25.9344C180.204 25.9344 179.144 25.4098 178.416 24.4262C177.687 23.377 177.356 21.8688 177.356 19.9672C177.356 18.0656 177.687 16.5574 178.416 15.5082C179.144 14.459 180.204 14 181.793 14C183.383 14 184.509 14.5246 185.171 15.5082C185.899 16.5574 186.231 18.0656 186.231 19.9672Z" fill="currentColor"/>
          <path d="M208.288 19.9011C208.023 19.7043 207.692 19.5732 207.361 19.442C207.957 19.1797 208.42 18.7863 208.818 18.2617C209.347 17.4748 209.612 16.4912 209.612 15.3765C209.612 13.4748 208.884 12.1634 207.493 11.3765C206.168 10.6552 204.182 10.2617 201.599 10.2617H195.373V29.6715H202.791C205.043 29.6715 206.765 29.1469 208.089 28.1633C209.414 27.1142 210.076 25.7371 210.076 23.901C210.076 22.8519 209.944 21.9994 209.612 21.4092C209.281 20.7535 208.884 20.2289 208.288 19.9011ZM200.142 21.5404H202.327C203.519 21.5404 204.314 21.7371 204.711 22.1306C205.109 22.4584 205.307 22.983 205.307 23.5732C205.307 24.2289 205.109 24.8191 204.711 25.2125C204.314 25.606 203.519 25.8027 202.46 25.8027H200.142V21.5404ZM204.314 17.2125C203.917 17.5404 203.188 17.6715 202.128 17.6715H200.142V14.1961H201.93C202.989 14.1961 203.784 14.3273 204.248 14.5896C204.645 14.8519 204.91 15.2453 204.91 15.8355C204.91 16.4912 204.711 16.9502 204.314 17.2125Z" fill="currentColor"/>
          <path d="M232.064 14.7877C231.402 13.2795 230.342 12.0992 229.018 11.3123C227.627 10.4598 225.905 10.0664 223.785 10.0664C221.666 10.0664 219.878 10.4598 218.487 11.3123C217.096 12.0992 216.037 13.2795 215.374 14.7877C214.712 16.2303 214.381 18.0008 214.381 19.968C214.381 21.9352 214.712 23.7057 215.374 25.2139C216.037 26.7221 217.096 27.9024 218.487 28.7549C219.878 29.6074 221.666 30.0008 223.719 30.0008C225.839 30.0008 227.627 29.6074 228.951 28.7549C230.342 27.9024 231.336 26.7221 231.998 25.2795C232.66 23.7713 232.991 22.0664 232.991 20.0336C233.058 18.0008 232.726 16.2303 232.064 14.7877ZM228.157 19.968C228.157 21.8697 227.825 23.3779 227.097 24.427C226.435 25.4762 225.309 25.9352 223.719 25.9352C222.13 25.9352 221.07 25.4106 220.341 24.427C219.613 23.3779 219.282 21.8697 219.282 19.968C219.282 18.0664 219.613 16.5582 220.341 15.509C221.07 14.4598 222.13 14.0008 223.719 14.0008C225.309 14.0008 226.435 14.5254 227.097 15.509C227.825 16.5582 228.157 18.0664 228.157 19.968Z" fill="currentColor"/>
          <path d="M106.362 10.2617H101.66L97.5535 17.6715H94.507V10.2617H89.8047V29.6715H94.507V22.2617H97.8184L102.123 29.6715H107.488L101.329 19.5732L106.362 10.2617Z" fill="currentColor"/>
          <path d="M169.876 10.2617H165.173L161.067 17.6715H157.954V10.2617H153.318V29.6715H157.954V22.2617H161.332L165.637 29.6715H171.002L164.842 19.5732L169.876 10.2617Z" fill="currentColor"/>
          <path opacity="0.4" d="M13.0738 22.234L5.82496 29.4393L10.5885 34.1585L17.8143 26.9761L13.0738 22.234Z"
                fill="#0399E9"/>
          <path opacity="0.2" d="M10.1523 19.3086L0.0504292 29.4141L5.82624 29.4371L13.0748 22.232L10.1523 19.3086Z"
                fill="#0399E9"/>
          <path opacity="0.6" d="M10.6076 39.982L20.7095 29.8766L17.8101 26.9761L10.5846 34.1582L10.6076 39.982Z"
                fill="#0399E9"/>
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M28.8111 18.0935L21.9307 11.2107C20.9182 10.1978 19.2614 10.1978 18.2489 11.2107L10.149 19.3135L20.7111 29.8793L28.8111 21.7765C29.8236 20.7637 29.8236 19.1063 28.8111 18.0935ZM18.4561 21.5463L18.433 16.2749L23.7256 21.5694L18.4561 21.5463Z"
                fill="#0399E9"/>
          <path d="M39.999 1.64623L38.3652 0.0117188L32.8886 5.49077L34.5224 7.12528L39.999 1.64623Z" fill="#0399E9"/>
          <path d="M39.9749 38.3655L34.498 32.8867L32.8642 34.5212L38.3411 40L39.9749 38.3655Z" fill="#0399E9"/>
          <path d="M7.11143 5.49077L1.63477 0.0117188L0.00097108 1.64623L5.47764 7.12528L7.11143 5.49077Z"
                fill="#0399E9"/>
          <path d="M18.4883 5.92578H20.7988V0.000420924H18.4883V5.92578Z" fill="#0399E9"/>
          <path d="M34.0703 19.2236V21.5352H39.9931V19.2236H34.0703Z" fill="#0399E9"/>
          <path d="M0 39.9996V34.8496L5.14611 39.9996H0Z" fill="#0399E9"/>
        </svg>
      </div>
    )
  }
  return (
    <div className={className}>
      <svg width="auto" height="auto" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.4" d="M13.0738 22.234L5.82496 29.4393L10.5885 34.1585L17.8143 26.9761L13.0738 22.234Z"
              fill="#0399E9"/>
        <path opacity="0.2" d="M10.1523 19.3086L0.0504292 29.4141L5.82624 29.4371L13.0748 22.232L10.1523 19.3086Z"
              fill="#0399E9"/>
        <path opacity="0.6" d="M10.6076 39.982L20.7095 29.8766L17.8101 26.9761L10.5846 34.1582L10.6076 39.982Z"
              fill="#0399E9"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M28.8111 18.0935L21.9307 11.2107C20.9182 10.1978 19.2614 10.1978 18.2489 11.2107L10.149 19.3135L20.7111 29.8793L28.8111 21.7765C29.8236 20.7637 29.8236 19.1063 28.8111 18.0935ZM18.4561 21.5463L18.433 16.2749L23.7256 21.5694L18.4561 21.5463Z"
              fill="#0399E9"/>
        <path d="M39.999 1.64623L38.3652 0.0117188L32.8886 5.49077L34.5224 7.12528L39.999 1.64623Z" fill="#0399E9"/>
        <path d="M39.9749 38.3655L34.498 32.8867L32.8642 34.5212L38.3411 40L39.9749 38.3655Z" fill="#0399E9"/>
        <path d="M7.11143 5.49077L1.63477 0.0117188L0.00097108 1.64623L5.47764 7.12528L7.11143 5.49077Z"
              fill="#0399E9"/>
        <path d="M18.4883 5.92578H20.7988V0.000420924H18.4883V5.92578Z" fill="#0399E9"/>
        <path d="M34.0703 19.2236V21.5352H39.9931V19.2236H34.0703Z" fill="#0399E9"/>
        <path d="M0 39.9996V34.8496L5.14611 39.9996H0Z" fill="#0399E9"/>
      </svg>
      <p>ОБРАЗОВАТЕЛЬНАЯ ПЛАТФОРМА "ШКОЛКОВО"</p>
    </div>

  );
};

export default Logo;
