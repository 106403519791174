import React from 'react';
import styles from './BigButton.module.scss';
const BigButton = ({ onClick, children, className }) => {
  return (
    <a href="https://3.shkolkovo.online" className={`${styles.btn} ${className}`}>
      {children}
    </a>
  );
};

export default BigButton;
