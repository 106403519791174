import React from 'react';
import styles from './FeedBack.module.scss';

const FeedBack = () => {
  return (
    <section className={styles.container}>
      <div
        className={`
            ${styles.container__wrapper} 
            ${styles.image}
          `}
      >
        <a
          href="https://vk.com/app5898182_-118664176#s=3040638&force=1"
          target="_blank"
          rel="noreferrer"
          className={`${styles.link} ${styles.link_blockWhite}`}
        >
          <p className={`${styles.text} ${styles.text_blockWhite}`}>
            Связаться с технической поддержкой
          </p>

          <img loading="lazy" className={styles.image__people} src="/PeopleFeedBack1.png" alt="people"/>
          <img
            loading="lazy"
            className={`${styles.card__img} ${styles.card__img_backgroundMobile}`}
            src="/supportGroup.svg"
            alt="pic"
          />
          <>
            <div
              className={`${styles.card__button}`}
            >
            </div>
            <button className={styles.btn}>
              Задать вопрос
            </button>
          </>
        </a>
      </div>
    </section>
  );
};

export default FeedBack;
