import React from 'react';
import styles from './Banner.module.scss';

const Banner = () => {
  return (
    <section className={styles.banner}>
      <div className={styles.banner__title}>
        <h1>
          ОБРАЗОВАТЕЛЬНАЯ
          <br/>
          ПЛАТФОРМА
          <br/>
          "ШКОЛКОВО"
        </h1>
      </div>
      <img className={styles.banner__img} src="/cat.png" alt="cat"/>
    </section>
  );
};

export default Banner;
