import React from 'react';
import styles from './InfoBlock.module.scss';

const InfoBlock = () => {
  return (
    <section className={styles.infoBlock}>
      <div className={styles.infoBlock__cover}>
        <p>Образовательная платформа ШКОЛКОВО</p>
        <ul>
          <li>
            <a href="#">
              Функциональные характеристики и общее руководство пользователя ПО «Образовательная платформа ШКОЛКОВО»
            </a>
          </li>
          <li>
            <a href="#">
              Инструкция по установке(работе платформы) «Образовательная платформа ШКОЛКОВО»
            </a>
          </li>
          <li>
            <a href="#">
              Описание процессов жизненного цикла ПО «Образовательная платформа ШКОЛКОВО»
            </a>
          </li>
        </ul>
      </div>
      <div className={`${styles.infoBlock__cover} ${styles.infoBlock__cover_theme_transparent}`}>
        <p>Расчёт стоимости</p>
        <ul>
          <li>
            <a href="#">
              Стоимость программного обеспечения рассчитывается индивидуально. Для точного расчета стоимости отправьте
              заявку нашим менеджерам.
            </a>
          </li>
        </ul>
        <div className={styles.infoBlock__contacts}>
          <div>
            <p>Телефон</p>
            <a href="tel:78003029279">8 (800) 302-92-79</a>
          </div>
          <div>
            <p>Электронная почта</p>
            <a href="mailto:info@shkolkovo.net" className="email">info@shkolkovo.net</a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InfoBlock;
