import React from 'react';
import styles from './Footer.module.scss';
import Logo from '../icons/Logo'
import Tg from "../icons/Tg";
import Vk from "../icons/Vk";


const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <div className={styles.footer__wrap} id="footer-unique-10">
      <div className={styles.footer__background}>
        <div className={`rowNew ${styles.footer}`}>
          <div className={styles.footer__main}>
            <div className={`${styles.footer__main_block}`}>
              <div className={`${styles.footer__logo}`}>
                <Logo className={`${styles.logo}`} isFull={true}/>
              </div>
              <nav className={styles.footer__nav}>
                <a href="https://3.shkolkovo.online/contacts" className={`${styles.footer__text} ${styles.footer__text_link}`}>Контакты</a>
                <a href="https://3.shkolkovo.online/reviews" className={`${styles.footer__text} ${styles.footer__text_link}`}>Отзывы</a>
                <a href="https://3.shkolkovo.online/level-up" target="_blank" rel="noreferrer" className={`${styles.footer__text} ${styles.footer__text_link}`}>Программа повышения квалификации</a>
                <a
                  className={`${styles.footer__text} ${styles.footer__text_link} ${styles.footer__text_icons}`}
                  target="_blank"
                  rel="noreferrer"
                  href="https://t.me/ShkolkovoOnline_bot "
                >
                  <Tg className={`${styles.footer__text_icon}`}/>
                  Бот Школково в ТГ
                </a>
                <a href="https://3.shkolkovo.online/special" className={`${styles.footer__text} ${styles.footer__text_link}`}>Акции</a>
                <a href="https://3.shkolkovo.online/theory" className={`${styles.footer__text} ${styles.footer__text_link}`}>Теория</a>
                <a href="https://3.shkolkovo.online/articles" className={`${styles.footer__text} ${styles.footer__text_link}`}>Статьи</a>
              </nav>
            </div>
            <div className={`${styles.footer__main_block}`}>
              <p className={`${styles.footer__text} ${styles.footer__text_opacity}`}>Мы в социальных сетях</p>
              <div className={styles.footer__icons}>
                <a href='https://vk.com/shkolkovo_ege' target="_blank"
                   rel="noreferrer">
                  <div className={styles.footer__icon}>
                    <Vk/>
                  </div>
                </a>
              </div>

            </div>
          </div>
          <div className={`${styles.footer__main} ${styles.footer__main_second}`}>
            <p className={`${styles.footer__text} ${styles.footer__text_opacity}`}>
              ©
              {year}
              {' '}
              «Школково». Все права защищены.
            </p>
            <nav className={`${styles.footer__nav_block}`}>
                <a href="https://3.shkolkovo.online/privacy" className={`${styles.footer__text} ${styles.footer__text_linkUnderline}`}>
                  Политика
                  конфиденциальности
                </a>
                <a href="https://3.shkolkovo.online/legal" className={`${styles.footer__text} ${styles.footer__text_linkUnderline}`}>Сведения об образовательной
                  организации</a>
                <a href="https://3.shkolkovo.online/agreement" className={`${styles.footer__text} ${styles.footer__text_linkUnderline}`}>Договор оферты</a>
                <a href="https://3.shkolkovo.online/partner_agreement" className={`${styles.footer__text} ${styles.footer__text_linkUnderline}`}>Партнерам</a>
              <a
                className={`${styles.footer__text} ${styles.footer__text_linkUnderline}`}
                href="https://vk.me/join/qG2djgQxT_FIiTcaRoc5UQyFHV_yMOFmFQE="
                target="_blank"
                rel="noreferrer"
              >
                Техническая поддержка
              </a>
              <p
                style={{color: 'var(--textBlack)', display: 'block', textDecoration: 'none'}}
                className={`${styles.footer__text} ${styles.footer__text_linkUnderline}`}
              >
                Образовательная лицензия № Л035-01298-77/00646528 от 05 апреля 2023
                {' '}
                <span>
                    <a
                      href="https://3.shkolkovo.online/public/docs/license.zip"
                      download
                      style={{color: 'var(--accent)', marginTop: 0}}
                    >
                      (скачать)
                    </a>
                  </span>
              </p>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
