const Data = {
  Title: 'Образовательная платформа ШКОЛКОВО',
  Description: '«Образовательная платформа ШКОЛКОВО» - это интернет-сервис (SaaS-решение) для подготовки к экзаменам, олимпиадам и вступительным испытаниям (ДВИ). Платформа объединяет все этапы обучения в единую экосистему, обеспечивая пользователям удобный и эффективный процесс подготовки.',
  PCTitle: 'Функциональные характеристики ПО',
  PCDescription: '«Образовательная платформа ШКОЛКОВО»(далее - Школково) представляет собой единую экосистему, где ученик, купивший курс, может обучаться на платформе.',
  Questions: {
    QTitle: 'Задачи, решаемые с помощью ШКОЛКОВО',
    List: [
      {
        Title: 'Подготовка к экзаменам, олимпиадам и ДВИ',
        Description: 'Платформа предоставляет доступ к образовательным курсам, разработанным преподавателями, которые направлены на всестороннее изучение экзаменационных и олимпиадных материалов',
      },
      {
        Title: 'Помощь в обучении и повышение уровня знаний',
        Description: 'Благодаря единой среде, где проводятся и хранятся вебинары, работает система проверки и выдачи домашних заданий, а также располагается база задач, пользователи, купившие курс, могут освоить даже самый сложный материал.',
      },
      {
        Title: 'Отслеживание уровня успеваемости',
        Description: 'Образовательная платформа ШКОЛКОВО мотивирует учеников к обучению. Полная аналитика решенных задач и статистика успеваемости, подкрепленная кураторской помощью, позволяет работать над пробелами в знаниях.',
      },
      {
        Title: 'Индивидуальный подход и поддержка в обучении',
        Description: 'Разбор возникающих вопросов и трудностей в освоении, происходит в онлайн-формате. Любой ученик курса может задать интересующий вопрос не только в чате, но и во время проведение онлайн-вебинара, где эксперт в лице преподавателя, будет готов дополнительно объяснить материал.',
      },
      {
        Title: 'Доступность образовательной программы',
        Description: 'Обучение происходит в онлайн-формате, позволяя ученикам заниматься из любой точки мира, используя современные устройства, в т.ч. компьютеры, ноутбуки, планшеты и мобильные телефоны',
      },
    ]
  }
}

export default Data