import React from 'react';

const Email = ({ className = '' }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <g opacity="0.4">
        <path opacity="0.4" d="M31.9994 10.2859L26.2852 6.61279V13.9591L31.9994 10.2859Z" fill="white"/>
        <path opacity="0.4" d="M23.1104 4.57143L15.9995 0L8.88867 4.57143H23.1104Z" fill="white"/>
        <path opacity="0.4" d="M5.71429 6.61157L0 10.2859L5.71429 13.959V6.61157Z" fill="white"/>
      </g>
      <path d="M26.2863 13.959L16.0006 20.5715L5.71484 13.959V4.57153H26.2863V13.959Z" fill="white"/>
      <path opacity="0.4" d="M0 32.0001H32L16 20.5715L0 32.0001Z" fill="white"/>
      <path opacity="0.2" d="M0 10.2856V31.9999L16 20.5714L0 10.2856Z" fill="white"/>
      <path opacity="0.6" d="M32 10.2856V31.9999L16 20.5714L32 10.2856Z" fill="white"/>
    </svg>
  );
};

export default Email;
