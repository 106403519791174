import React from 'react';
import Data from "../../datas/data";
import styles from './Content.module.scss'

const Content = () => {

  const {
    Title,
    Description,
    PCDescription,
    PCTitle,
    Questions: {
      QTitle,
      List
    },
  } = Data;

  return (
    <section className={styles.content}>
      {/*<h1>{Title}</h1>*/}
      <p>{Description}</p>
      <h2>{PCTitle}</h2>
      <p>{PCDescription}</p>
      <h2>{QTitle}</h2>

      {
        List.map((item, index) => (
          <>
            <p className={styles.bold}><span className={styles.arrow}/>{item.Title}</p>
            <p>{item.Description}</p>
          </>
        ))
      }
    </section>
  );
};

export default Content;
