import React from 'react';
import styles from './Contacts.module.scss';
import Email from "../icons/Email";
import Phone from "../icons/Phone";

const Contacts = () => {
  return (
    <section className={styles.contacts}>
      <div className={styles.contacts__cover}>
        <p>Телефон</p>
        <a href="tel:78003029279">8 (800) 302-92-79</a>
        <div className={styles.contacts__iconCover}>
          <Phone className={styles.contacts__icon}/>
          <p>
            Звоните с 9:00 до 18:00
            <br/>
            Выходной Сб, Вс
          </p>
        </div>
      </div>
      <div className={styles.contacts__cover}>
        <p>Электронная почта</p>
        <a href="mailto:info@shkolkovo.net" className="email">info@shkolkovo.net</a>
        <div className={styles.contacts__iconCover}>
          <Email className={styles.contacts__icon}/>
          <p>
            Будем рады помочь.
            <br/>
            Пишите письма
          </p>
        </div>
      </div>
    </section>
  );
};

export default Contacts;
