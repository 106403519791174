import React from 'react';
import styles from './Direction.module.scss';

const mas = [
  {
    Id: '01',
    Name: 'Загрузки(добавление) и хранения обучающих материалов на платформу Школково\n',
    // Img: '/public/new-site/images/direction-block/ege.png',
    Link: '#',
  },
  {
    Id: '02',
    Name: 'Предоставления разного формата контента (курсы, видео, изображения, текстовые документы, домашние задания и другое), который открывается после оплаты\n',
    // Img: '/public/new-site/images/direction-block/oge.png',
    Link: '#',
  },
  {
    Id: '03',
    Name: 'Просмотра и чтения контента на платформе Школково, включая онлайн-трансляции и записи прошедших вебинаров\n',
    // Img: '/public/new-site/images/direction-block/olymp.png',
    Link: '#',
  },
  {
    Id: '04',
    Name: 'Скачивания материалов с платформы\n',
    // Img: '/public/new-site/images/direction-block/students-2.png',
    Link: '#',
  },
  {
    Id: '05',
    Name: 'Отслеживания статистики\n',
    // Img: '/public/new-site/images/direction-block/school-2.png',
    Link: '#',
  },
  {
    Id: '06',
    Name: 'Общения с кураторами и экспертами\n',
    // Img: '/public/new-site/images/direction-block/school-2.png',
    Link: '#',
  },
];

const Direction = ({ title, info }) => {
  const actualPlan = info || mas;
  return (
    <div className={`rowNew ${styles.direction}`}>
      <h2>Программное обеспечение предназначено для:</h2>
      <div className={styles.direction__list}>
        {actualPlan.map((item, index) => (
            <div key={index}className={styles.direction__block}>
              { item?.Name.includes(item.Type)
                ? <p>{item.Name}</p>
                : (
                  <p>
                    {item.Type}
                    {item.separator && item.separator}
                    {item.Name}
                  </p>
                ) }
              <span>{index + 1 > 9 ? (index + 1) : `0${index + 1}`}</span>
              {/*{item.Img ? <img loading="lazy" src={item.Img} /> : <></>}*/}
            </div>
        ))}
      </div>
      <p>*Программное обеспечение предоставляет интернет сервис (SaaS-решение) для управления содержимым в соответствии с предъявляемыми требованиями по протоколу https.</p>
    </div>
  );
}
export default Direction;