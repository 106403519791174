import React from 'react';

const Vk = ({ className = '' }) => {
  return (
    <svg className={className} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="8" fill="#0399E9"/>
      <path
        d="M26.9283 25.834H29.2202C29.8887 25.834 30.1752 25.4523 29.8887 24.7844C29.6977 24.1164 28.9338 23.2576 27.9788 22.1126C27.5013 21.5401 26.7373 20.8722 26.4509 20.4905C26.0689 20.0134 26.1644 19.8225 26.4509 19.4408C26.4509 19.4408 29.2203 15.5286 29.5068 14.1928C29.6977 13.7157 29.5067 13.334 28.8383 13.334H26.5464C25.9734 13.334 25.7824 13.6202 25.5914 14.0019C25.5914 14.0019 24.4454 16.8645 22.822 18.6775C22.3445 19.1546 22.058 19.3454 21.7715 19.3454C21.5805 19.3454 21.3896 19.1546 21.3896 18.6775V14.1928C21.3896 13.6202 21.1986 13.334 20.7211 13.334H17.0922C16.7102 13.334 16.5192 13.6202 16.5192 13.9065C16.5192 14.3836 17.3787 14.5744 17.4742 16.1012V19.4408C17.4742 20.2042 17.3787 20.2996 17.0922 20.2996C16.2328 20.2996 14.4183 17.437 13.2724 14.1928C12.9859 13.6202 12.7949 13.334 12.2219 13.334H9.93C9.26153 13.334 9.16602 13.6202 9.16602 14.0019C9.16602 14.5744 9.93 17.5325 12.7949 21.5401C14.7048 24.3073 17.3787 25.7386 19.7661 25.7386C21.1986 25.7386 21.3896 25.4523 21.3896 24.8798V22.876C21.3896 22.208 21.485 22.1126 21.9625 22.1126C22.3445 22.1126 22.9175 22.208 24.2545 23.5439C25.7824 25.166 26.0689 25.834 26.9283 25.834Z"
        fill="white"/>
    </svg>
  );
};

export default Vk;
