import React from 'react';

const Phone = ({ className = '' }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path opacity="0.6" d="M27.4283 4.57129H25.1426V27.428H27.4283V4.57129Z" fill="white"/>
      <path opacity="0.2" d="M18.2857 13.7141H16V18.2855H18.2857V13.7141Z" fill="white"/>
      <path
        d="M3.42851 31.9994H12.5712V22.8567H7.985C7.985 22.8567 6.84217 19.6431 6.84217 15.9997C6.84217 12.3564 7.985 9.1427 7.985 9.1427H12.5712V0H3.42851C3.42851 0 0 6.92902 0 15.9997C0 25.0704 3.42851 31.9994 3.42851 31.9994Z"
        fill="white"/>
      <path opacity="0.8" d="M31.9986 0H29.7129V31.9994H31.9986V0Z" fill="white"/>
      <path opacity="0.4" d="M22.856 9.14258H20.5703V22.8566H22.856V9.14258Z" fill="white"/>
    </svg>
  );
};

export default Phone;
