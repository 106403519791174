import React from 'react';

const Tg = ({ className = '' }) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5 0C2.23858 0 0 2.23858 0 5V43C0 45.7614 2.23858 48 5 48H43C45.7614 48 48 45.7614 48 43V5C48 2.23858 45.7614 0 43 0H5ZM25.1108 18.546C23.3202 19.3019 19.7414 20.8667 14.3745 23.2401C13.5029 23.5919 13.0464 23.936 13.0048 24.2726C12.9346 24.8413 13.6363 25.0652 14.5918 25.3702C14.7217 25.4117 14.8563 25.4546 14.9943 25.5001C15.9343 25.8103 17.1987 26.1732 17.8561 26.1875C18.4523 26.2006 19.1178 25.9511 19.8525 25.439C24.867 22.0032 27.4555 20.2666 27.618 20.2291C27.7326 20.2027 27.8915 20.1696 27.9992 20.2666C28.1068 20.3637 28.0962 20.5477 28.0848 20.597C28.0153 20.8979 25.2602 23.4978 23.8352 24.8425C23.3913 25.2614 23.0764 25.5585 23.012 25.6264C22.8677 25.7785 22.7206 25.9225 22.5792 26.0608C21.706 26.9153 21.0511 27.5561 22.6155 28.6025C23.3678 29.1056 23.9696 29.5217 24.5702 29.9368C25.2252 30.3896 25.8787 30.8413 26.724 31.4037C26.9396 31.5471 27.1454 31.6961 27.3459 31.8412C28.1086 32.3931 28.7939 32.8889 29.6405 32.8098C30.1324 32.7639 30.6405 32.2944 30.8986 30.894C31.5085 27.5845 32.7073 20.414 32.9844 17.4591C33.0207 17.0719 33.0189 16.4869 32.6916 16.2174C32.4711 16.0358 32.1306 15.9974 31.9783 16.0001C31.2859 16.0125 30.2235 16.3875 25.1108 18.546Z"
        fill="currentColor"
      />
    </svg>

  );
};

export default Tg;
