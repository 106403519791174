import React from 'react';
import Logo from "../icons/Logo";
import BigButton from "../BigButton/BigButton";
import styles from './Header.module.scss'

const Header = () => {
  return (
    <header className={styles.header}>
      <Logo className={styles.header__logo}/>
      <BigButton>
        Перейти на официальный сайт
      </BigButton>
    </header>
  );
};

export default Header;
